import React from 'react';
import logo from './logo.svg';
import './App.css';

import TimeAgo, { Formatter, Suffix, Unit } from 'react-timeago';

type StringOrFn = string | ((value: number, millisDelta: number) => string)
type NumberArray = [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
]

export type L10nsStrings = {
  prefixAgo?: string,
  prefixFromNow?: string,
  suffixAgo?: string,
  suffixFromNow?: string,
  second?: string,
  seconds?: string,
  minute?: string,
  minutes?: string,
  hour?: string,
  hours?: string,
  day?: string,
  days?: string,
  week?: string,
  weeks?: string,
  month?: string,
  months?: string,
  year?: string,
  years?: string,
  wordSeparator?: string,
  numbers?: string,
}

var strings: L10nsStrings = {
  prefixAgo: 'hace',
  prefixFromNow: 'dentro de',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: 'menos de un minuto',
  minute: 'un minuto',
  minutes: 'unos %d minutos',
  hour: 'una hora',
  hours: '%d horas',
  day: 'un día',
  days: '%d días',
  month: 'un mes',
  months: '%d meses',
  year: 'un año',
  years: '%d años',
};

const normalizeNumber = (numbers: NumberArray | undefined, value: number) =>
  numbers && numbers.length === 10
    ? String(value)
        .split('')
        .map((digit: string) =>
          digit.match(/^[0-9]$/)
            ? parseInt(digit)
            : digit,
        )
        .join('')
    : String(value)

const normalizeFn =
  (value: number, distanceMillis: number, numbers?: NumberArray) =>
  (stringOrFn: StringOrFn) =>
    typeof stringOrFn === 'function'
      ? stringOrFn(value, distanceMillis).replace(
          /%d/g,
          normalizeNumber(numbers, value),
        )
      : stringOrFn.replace(/%d/g, normalizeNumber(numbers, value))

function formatter(value: number,
  unit: Unit,
  suffix: Suffix,
  epochMiliseconds: number,
  nextFormatter?: Formatter) {
    var current = Date.now();
    // convert weeks to days if strings don't handle weeks
    if (unit === 'week' && !strings.week && !strings.weeks) {
      var days = Math.round(Math.abs(epochMiliseconds - current) / (1000 * 60 * 60 * 24));
      value = days;
      unit = 'day';
    }

    // create a normalize function for given value
    const normalize = normalizeFn(
      value,
      current - epochMiliseconds,
      undefined,
    )

    const dateString: Array<string> = []

    // handle prefixes
    if (suffix === 'ago' && strings.prefixAgo) {
      dateString.push(normalize(strings.prefixAgo))
    }
    if (suffix === 'from now' && strings.prefixFromNow) {
      dateString.push(normalize(strings.prefixFromNow))
    }

    // Handle Main number and unit
    const isPlural = value > 1
    const units: Unit = `${unit}s` as Unit;
    if (isPlural) {
      const stringFn: StringOrFn =
        strings[units] || strings[unit] || '%d ' + unit
      dateString.push(normalize(stringFn))
    } else {
      const stringFn: StringOrFn =
        strings[unit] || strings[units] || '%d ' + unit
      dateString.push(normalize(stringFn))
    }

    // Handle Suffixes
    if (suffix === 'ago' && strings.suffixAgo) {
      dateString.push(normalize(strings.suffixAgo))
    }
    if (suffix === 'from now' && strings.suffixFromNow) {
      dateString.push(normalize(strings.suffixFromNow))
    }

    // join the array into a string and return it
    const wordSeparator = ' '
    return dateString.join(wordSeparator)
}

function App() {
  const lastPenalty = Date.parse("18 Oct 2023 20:50:00 -300");

  return (
    <div className="App">
      <header className="App-header">
        <h1>Cuando fue el último penal para Peñarol?</h1>
        <div>vs Montevideo City Torque: <strong><TimeAgo date={lastPenalty} formatter={formatter}/></strong></div>
      </header>
    </div>
  );
}

export default App;
